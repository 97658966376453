import { render, staticRenderFns } from "./TrashIcon.vue?vue&type=template&id=7de79c64&scoped=true"
import script from "./TrashIcon.vue?vue&type=script&lang=js"
export * from "./TrashIcon.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7de79c64",
  null
  
)

export default component.exports