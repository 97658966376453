<template>
  <div class="footer-image-container" :class="[divider ? 'divider' : '']">
    <div class="footer-image-content">
      <h5 v-if="title" class="footer-image-title">{{ title }}</h5>
      <div class="footer-image-list">
        <span class="footer-image" v-for="(item, index) in items" :key="index">
          <a v-if="item.url" :href="item.url" :target="item.target">
            <nuxt-img
              :src="item.image"
              :alt="item.alt || `image-list-item-${index}`"
              :width="item.width"
              :height="item.height"
              placeholder="/assets/icon_placeholder.svg"
              loading="lazy"
            />
          </a>
          <nuxt-img
            v-else
            :src="item.image"
            :alt="item.alt || `image-list-item-${index}`"
            :width="item.width"
            :height="item.height"
            placeholder="/assets/icon_placeholder.svg"
            loading="lazy"
          />
        </span>
      </div>
    </div>
    <SfLink :link="additionalLink" target="_blank">
      <nuxt-img
        v-if="additionalImage"
        :key="additionalImage.image"
        class="footer-image additional-image"
        :src="additionalImage.image"
        :alt="additionalImage.alt"
        :width="additionalImage.width"
        :height="additionalImage.height"
        placeholder="/assets/icon_placeholder.svg"
        loading="lazy"
    /></SfLink>
  </div>
</template>

<script>
import { addBasePath } from '~/helpers/addBasePath';
import { SfLink } from '@storefront-ui/vue';
import { useI18n } from '~/helpers/hooks/usei18n';
import { computed } from '@nuxtjs/composition-api';

export default {
  name: 'IconList',
  components: {
    SfLink,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    items: {
      type: Array,
      default: null,
    },
    divider: {
      type: Boolean,
      default: false,
    },
    additionalImage: {
      type: Object,
      default: null,
    },
  },
  setup() {
    const { localeProperties } = useI18n();
    const additionalLink = computed(
      () =>
        `https://www.feedaty.com/feedaty/reviews/bestdigit?gui=${localeProperties.iso.replace(
          '_',
          '-'
        )}`
    );
    return {
      addBasePath,
      additionalLink,
    };
  },
};
</script>

<style lang="scss" scoped>
.footer-image-container {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
  &.divider {
    .footer-image-content {
      .footer-image-list {
        .footer-image {
          padding: 0 10px;
          border-left: solid 1px var(--c-grey-2);
        }
      }
    }
  }

  .footer-image-content {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 15px;
    .footer-image-title {
      color: var(--c-grey-3);
      font-size: var(--font-size--3xs);
      line-height: var(--font-size--xs);
      font-weight: var(--font-weight--normal);
    }
    .footer-image-list {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;

      .footer-image {
        &:first-child {
          border-left: none;
        }
      }
    }
  }
}
//mobile
@media (max-width: $tablet-max) {
  .footer-image-container {
    padding: 30px 0 0 0;
    &.divider {
      padding: 30px 20px 0 20px;
    }
  }
}
//desktop
@media (min-width: $desktop-min) {
  .footer-image-container {
    &.divider {
      padding: 0 20px;
    }
    &:not(.divider) {
      max-width: 70%;
    }
  }
}
//desktop xxl
@media (min-width: $desktop-xxl-min) {
  .footer-image-container {
    &:not(.divider) {
      max-width: none;
    }
  }
}
</style>
