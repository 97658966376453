// eslint-disable-next-line import/no-extraneous-dependencies
import { Context as NuxtContext } from '@nuxt/types';
import { merge } from 'lodash-es';
import c from 'cookie';

export type ApiClientMethod = (...args: any) => Promise<any>;

interface CreateProxiedApiParams {
  givenApi: Record<string, ApiClientMethod>;
  client: any;
  tag: string;
}

export const createProxiedApi = ({ givenApi, client, tag }: CreateProxiedApiParams) =>
  new Proxy(givenApi, {
    get: (target, prop, receiver) => {
      const functionName = String(prop);
      if (Reflect.has(target, functionName)) {
        return Reflect.get(target, prop, receiver);
      }

      // eslint-disable-next-line @typescript-eslint/require-await
      return async (...args) => client.post(`/${tag}/${functionName}`, args).then((r) => r.data);
    },
  });

export const getCookies = (context: NuxtContext) => context?.req?.headers?.cookie ?? '';

const getMarketAndCurrencyFromEnv = (cleanLocaleFromUrl: string) => {
  if (process.env[`MKT_${cleanLocaleFromUrl}`]) {
    return process.env[`MKT_${cleanLocaleFromUrl}`].split('|');
  }

  if (cleanLocaleFromUrl === '' || process.env.MKT_it) {
    return process.env.MKT_it.split('|');
  }
  return ['', ''];
};

export const getIntegrationConfig = (context: NuxtContext, configuration: any) => {
  let cookie = '';
  if (!process.client) {
    const cookiesObject = c.parse(context?.req?.headers?.cookie ?? '');
    const cleanLocaleFromUrl = context?.req?.url ? context?.req?.url.split('/')[1] : 'it';

    if (cleanLocaleFromUrl) {
      const itOrEn = cleanLocaleFromUrl === 'it' ? 'it' : 'en';
      const [market, currency] = getMarketAndCurrencyFromEnv(cleanLocaleFromUrl);
      cookiesObject['vsf-locale'] = itOrEn ?? 'it';
      cookiesObject['vsf-store'] = itOrEn ?? 'it';
      cookiesObject['vsf-market'] = market;
      cookiesObject['vsf-currency'] = currency;

      let newCookieString = '';
      Object.keys(cookiesObject).forEach((key) => {
        newCookieString += `${key}=${cookiesObject[key]}; `;
      });
      cookie = newCookieString.trim().slice(0, -1);
    }
  } else {
    cookie = getCookies(context);
  }

  return merge(
    {
      axios: {
        headers: {
          ...(cookie ? { cookie } : {}),
        },
      },
    },
    configuration
  );
};
