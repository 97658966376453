/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable promise/always-return */
/* eslint-disable promise/catch-or-return */
import { computed, ComputedRef, useContext } from '@nuxtjs/composition-api';
import cookieNames from '~/enums/cookieNameEnum';
import { LocaleObject } from 'nuxt-i18n';

const findCurrencyBasedOnStoreCode = (storeCode: string, locales: string[] | LocaleObject[]): string => {
  const match = (locales as LocaleObject[]).find((locale) => locale.code === storeCode);
  return match?.defaultCurrency;
};

type UseGeminiConfiguration = () => {
  selectedCurrency: ComputedRef<string | undefined>;
  selectedLocale: ComputedRef<string | undefined>;
  selectedStore: ComputedRef<string | undefined>;
  selectedMarket: ComputedRef<string | undefined>;
  loadConfiguration: (params: { updateCookies: boolean; updateLocale: boolean }) => Promise<void>;
};
// @ts-ignore
export const useGeminiConfiguration: UseGeminiConfiguration = () => {
  const { app, $vsf } = useContext();

  // @ts-ignore
  const { localesToMarkets } = $vsf.$gemini.config;

  const selectedCurrency = computed<string | undefined>(() => app.$cookies.get(cookieNames.currencyCookieName));
  const selectedLocale = computed<string | undefined>(() => app.$cookies.get(cookieNames.localeCookieName));
  const selectedStore = computed<string | undefined>(() => app.$cookies.get(cookieNames.storeCookieName));
  const selectedMarket = computed<string | undefined>(() => app.$cookies.get(cookieNames.marketCookieName));
  const selectedCountry = computed<string | undefined>(() => app.$cookies.get(cookieNames.countryCookieName));
  const selectedLanguage = computed<string | undefined>(() => app.$cookies.get(cookieNames.languageCookieName));

  const loadConfiguration: (params: { updateCookies: boolean; updateLocale: boolean }) => void = async () => {
    const { route } = useContext();
    const marketFromCookies = app.$cookies.get(cookieNames.marketCookieName);
    const storeCodeFromURL = route.value.path.split('/')[1];
    const i18nCurrentLocaleCode = storeCodeFromURL === 'it' ? 'it' : 'en';
    const currentLanguage = storeCodeFromURL === 'it' ? 'it-IT' : 'en-US';

    app.$cookies.set(cookieNames.marketCookieName, localesToMarkets[storeCodeFromURL].split('|')[0]);

    if (!selectedStore.value || selectedStore.value !== i18nCurrentLocaleCode) {
      app.$cookies.set(cookieNames.storeCookieName, i18nCurrentLocaleCode);
    }
    if (!selectedLocale.value || selectedLocale.value !== i18nCurrentLocaleCode) {
      app.$cookies.set(cookieNames.localeCookieName, i18nCurrentLocaleCode);
    }
    if (!selectedCurrency.value || selectedCurrency.value !== findCurrencyBasedOnStoreCode(storeCodeFromURL, app.i18n.locales)) {
      app.$cookies.set(cookieNames.currencyCookieName, findCurrencyBasedOnStoreCode(storeCodeFromURL, app.i18n.locales));
    }
    if (!selectedCountry.value || selectedCountry.value !== i18nCurrentLocaleCode) {
      app.$cookies.set(cookieNames.countryCookieName, i18nCurrentLocaleCode);
    }
    if (!selectedLanguage.value || selectedLanguage.value !== currentLanguage) {
      app.$cookies.set(cookieNames.languageCookieName, currentLanguage);
    }

    if (storeCodeFromURL) {
      if (!marketFromCookies) {
        app.$cookies.set(cookieNames.marketCookieName, localesToMarkets[storeCodeFromURL].split('|')[0] || localesToMarkets.en.split('|')[0]);
        app.$cookies.set(cookieNames.currencyCookieName, localesToMarkets[storeCodeFromURL].split('|')[1] || localesToMarkets.en.split('|')[1]);
      } else {
        const marketForCurrentURL = localesToMarkets[storeCodeFromURL].split('|')[0];
        if (marketFromCookies !== marketForCurrentURL) {
          app.$cookies.set(cookieNames.marketCookieName, marketForCurrentURL || localesToMarkets.en.split('|')[0]);
          app.$cookies.set(cookieNames.currencyCookieName, localesToMarkets[storeCodeFromURL].split('|')[1] || localesToMarkets.en.split('|')[1]);
        }
      }
    }
  };

  return {
    selectedCurrency,
    selectedLocale,
    selectedMarket,
    selectedStore,
    selectedLanguage,
    loadConfiguration,
  };
};
